<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> App版本列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button style="margin-right: 5px" v-if="hasAuth('SaveAppVersion')"
                           type="primary"
                           icon="el-icon-plus"
                           class="handle-del"
                           @click="$router.push('/app/version/edit')">发布新版本
                </el-button>
                <el-popover placement="top-start" trigger="hover" content="App应用">
                    <template #reference>
                        <el-select v-model="query.appId" class="handle-select" placeholder="App应用">
                            <el-option key="" label="全部" value=""></el-option>
                            <el-option :key="item.other" :label="item.name" :value="item.other"
                                       v-for="item in bases.appIds"></el-option>
                        </el-select>
                    </template>
                </el-popover>
                <el-popover placement="top-start" trigger="hover" content="App类型">
                    <template #reference>
                        <el-select v-model="query.type" class="handle-select" placeholder="App类型">
                            <el-option key="" label="全部" value=""></el-option>
                            <el-option :key="item.other" :label="item.name" :value="item.other"
                                       v-for="item in bases.appTypes"></el-option>
                        </el-select>
                    </template>
                </el-popover>
                <el-popover placement="top-start" trigger="hover" content="状态">
                    <template #reference>
                        <el-select v-model="query.state" placeholder="状态" class="handle-select">
                            <el-option key="" label="全部" value=""></el-option>
                            <el-option key="1" label="已发布" :value="1"></el-option>
                            <el-option key="0" label="未发布" :value="0"></el-option>
                        </el-select>
                    </template>
                </el-popover>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    :height="common.calcTableHeight(350)"
                    header-cell-class-name="table-header"
            >
                <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
                <el-table-column prop="appId" label="App应用" align="center">
                    <template #default="scope">
                        {{common.arrayFindReturn2(bases.appIds,scope.row.appId,"other","name")+"("+scope.row.appId+")"}}
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="App类型" align="center">
                    <template #default="scope">
                        {{common.arrayFindReturn2(bases.appTypes,scope.row.type,"other","name")+"("+scope.row.type+")"}}
                    </template>
                </el-table-column>
                <el-table-column prop="version" label="版本" align="center">
                    <template #default="scope">
                        {{scope.row.version}}({{scope.row.versionNumber}})
                    </template>
                </el-table-column>
                <el-table-column prop="stat" label="统计" align="center" width="150">
                    <template #default="scope">
                        <el-tooltip
                                class="item point"
                                effect="dark"
                                content="下载版本次数"
                                placement="top-start"><span>{{scope.row.downloadCount?scope.row.downloadCount:0}}</span>
                        </el-tooltip>
                        /
                        <el-tooltip
                                class="item point"
                                effect="dark"
                                content="获取版本次数"
                                placement="top-start"><span>{{scope.row.checkCount?scope.row.checkCount:0}}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="状态" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.state===1" class="success">已发布</span>
                        <span v-else>未发布</span>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="更新内容" align="center"></el-table-column>
                <el-table-column prop="updateDate" label="更新时间" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.updateDate">{{scope.row.updateDate.toDate('yyyy-MM-dd')}}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" class-name="table-op" width="160" align="center"
                                 v-if="hasAuth('UpdateAppVersion','DeleteAppVersion')">
                    <template #default="scope">
                        <el-button  v-if="hasAuth('GetAppVersion') && scope.row.state===1"
                                type="text"
                                icon="el-icon-view"
                                @click="$router.push({path:'/app/version/edit',query:{id:scope.row.id}})">查看
                        </el-button>
                        <el-button v-if="hasAuth('UpdateAppVersion') && scope.row.state===0"
                                          type="text"
                                          icon="el-icon-edit"
                                          @click="$router.push({path:'/app/version/edit',query:{id:scope.row.id}})">编辑
                        </el-button>
                        <el-button v-if="hasAuth('DeleteAppVersion') && scope.row.state===0"
                                          type="text"
                                          icon="el-icon-delete"
                                          class="red"
                                          @click="handleDelete(scope.$index, scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.page"
                        :page-size="query.rows"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../../utils/http";
    import common from "../../utils/common";
    import api from "../../utils/api";
    import store from "../../store";

    export default {
        name: "AppVersionList",
        data() {
            return {
                hasAuth: common.hasAuth,
                common: common,
                tableData: [],
                form: {},
                pageTotal: 0,
                query: {
                    state: null,
                    page: 1,
                    rows: 30
                },
                loading: false,
                servers: [],
                apps: [],
                app: {id: null},
                bases:{}
            };
        },
        created() {
            this.init();
            api.getBase("app_id", (data) => {
                this.bases.appIds = data;
            })
            api.getBase("app_type", (data) => {
                this.bases.appTypes = data;
            })
        },
        methods: {
            init(){
                this.getData();
            },
            getData() {
                if (this.app.id) {
                    this.query.appId = this.app.id;
                }
                http.post("/app/version/list", this.query).then(data => {
                    data = data.data;
                    if (data) {
                        this.tableData = data.rows;
                        this.pageTotal = data.total;
                        this.getAppVersionHistoryStat(common.getIds(this.tableData));
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取版本统计
            getAppVersionHistoryStat(ids) {
                if(!ids||ids.length<1){
                    return;
                }
                http.post("/app/version/history/stat/list", ids, {headers:{"Content-Type": 'application/json;charset=utf-8'},animation: store.state.Animation.NONE}).then(data => {
                    data = data.data;
                    if (data) {
                        let tableObj = {};
                        for (let i = 0; i <this.tableData.length; i++) {
                            tableObj[this.tableData[i].id] = this.tableData[i];
                        }
                        for (let i = 0; i < data.length; i++) {
                            let item = tableObj[data[i].id];
                            item['checkCount'] = data[i].checkCount;
                            item['downloadCount'] = data[i].downloadCount;
                        }
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            // 触发搜索按钮
            handleSearch() {
                this.query.page = 1;
                this.getData();
            },
            //查找数据内容
            findArray(arr, id) {
                return common.arrayFindReturn(arr, id);
            },
            // 删除操作
            handleDelete(index, row) {
                // 二次确认删除
                this.$confirm("确定要删除吗？", "提示", {
                    type: "warning"
                })
                    .then(() => {
                        http.post("/app/version/delete", {id: row.id}).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success("删除成功");
                                this.getData();
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    })
                    .catch(() => {
                    });
            },
            // 分页导航
            handlePageChange(val) {
                this.query.page = val;
                this.getData();
            },
        },
        watch: {
            $route() {
                if (this.$route.name === "AppVersionList") {
                    //初始化
                    this.init();
                }
            }
        },
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 120px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }
</style>
